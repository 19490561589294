const baseApi = "https://api.gunspacedev.com/index.php/";
//const baseApi = "http://localhost:8000/";
const apiVersion = "api/";

const request = (method, data, token) => {
  let request = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    timeout: 5000,
  };
  request.method = method.toUpperCase();
  if (
    (method.toUpperCase() === "PUT" || method.toUpperCase() === "POST") &&
    data
  ) {
    request.body = JSON.stringify(data);
  }
  if (token) {
    request.headers.Authorization = "Bearer " + token;
  }
  return request;
};
function rejectIfError(json) {
  if (json.code >= 300) {
    return Promise.reject(json);
  }
  return json;
}

export function handleResponseToJson(response) {
  if (!response) {
    return Promise.reject({
      error: true,
      msg: "No response from server",
    });
  }
  if (response.status && response.status >= 300) {
    let err = response.text();
    return Promise.reject({
      error: true,
      status: response.status,
      msg: "Error in server side",
      full: err,
    });
  }
  try {
    const json = response.json();

    return json.then(rejectIfError) || {};
  } catch (e) {
    return Promise.reject({
      error: true,
      code: response.code,
      msg: "Unable to parse response form server",
    });
  }
}

export function recoverPasswordAPI(data) {
  let route = baseApi + apiVersion + "password/recover";
  return fetch(route, request("post", data, null)).then(handleResponseToJson);
}
export function resetPasswordAPI(data) {
  let route = baseApi + apiVersion + "password/reset";
  return fetch(route, request("post", data, null)).then(handleResponseToJson);
}
