import logo from "../gunspace_final.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { resetPasswordAPI } from "../api/password";

function Reset() {
  const { token } = useParams();
  console.log({ token });
  const [data, setInput] = useState({
    token,
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({});
  console.log({ errors });
  const validate = (submittedInput) => {
    const newErrors = Object.assign({});
    let isValid = true;
    if (data["password"] !== data["password_confirmation"]) {
      newErrors["password_confirmation"] = "Passwords don't match.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validate(data);
    if (isValid) {
      try {
        await resetPasswordAPI(data);
        setInput({ email: "", password: "", password_confirmation: "", token });
        toast.success("Email Sent!");
      } catch (e) {
        console.log({ e });
        if (e["msg"]) {
          toast.error(e["msg"]);
        } else {
          toast.error("Sorry, something went wrong");
        }
      }
    }
  };
  const handleChange = (event) => {
    const newInput = Object.assign({}, data);
    newInput[event.target.name] = event.target.value;
    setInput(newInput);
  };
  return (
    <div className="Fullscreen Vertical">
      <img src={logo} className="App-logo" alt="logo" />
      <label>Enter your acocount email and new password to reset.</label>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="Vertical AlignCenter">
          <div className="Vertical AlignStart">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={data.email}
              required={true}
              onChange={handleChange}
              autoCapitalize="off"
              autoComplete="off"
            ></input>
          </div>
          <div className="Vertical AlignStart">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={data.password}
              required={true}
              onChange={handleChange}
              autoComplete="off"
              autoCapitalize="off"
            />
          </div>
          <div className="Vertical AlignStart">
            <label>Password Confirmation:</label>
            <input
              type="password"
              name="password_confirmation"
              value={data.password_confirmation}
              required={true}
              autoComplete="off"
              autoCapitalize="off"
              onChange={handleChange}
            />
            <div className="text-danger">{errors["password_confirmation"]}</div>
          </div>
          <br />
          <input type="submit" value="Send email"></input>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
export default Reset;
