import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Recover from "./pages/Recover";
import Reset from "./pages/Reset";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Recover />
          </Route>
          <Route path="/password/recover">
            <Recover />
          </Route>
          <Route path="/password/reset/:token">
            <Reset />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
