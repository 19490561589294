import logo from "../gunspace_final.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { recoverPasswordAPI } from "../api/password";
import { useState } from "react";

function Recover() {
  const [data, setData] = useState({ email: "" });
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await recoverPasswordAPI(data);
      setData({ email: "" });
      toast.success("Email Sent!");
    } catch (e) {
      console.log({ e });
      if (e["msg"]) {
        toast.error(e["msg"]);
      } else {
        toast.error("Sorry, something went wrong");
      }
    }
  };
  const handleChange = (event) => {
    const newInput = Object.assign({}, data);
    newInput[event.target.name] = event.target.value;
    setData(newInput);
  };
  return (
    <div className="Fullscreen Vertical">
      <img src={logo} className="App-logo" alt="logo" />
      <label>
        To reset your password enter your account email and you will receive a
        link to do so.
      </label>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="Vertical AlignCenter">
          <div className="Vertical AlignStart">
            <label>Email:</label>
            <input
              name="email"
              type="email"
              required={true}
              value={data.email}
              onChange={handleChange}
              autoComplete="off"
              autoCapitalize="off"
            ></input>
          </div>
          <br />
          <input type="submit" value="Send email"></input>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Recover;
